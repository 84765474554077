import React from 'react';

const DayToggle = (props) => {
	if(props.selected === true){
			return(
				<div className="btn btn-sm btn-success day-toggle m-1" onClick={() => props.handleClick(props.id)}>
					<span>{props.name}</span>
				</div>
			);
		}else{
			return(
				<div className="btn btn-sm btn-outline-secondary day-toggle m-1" onClick={() => props.handleClick(props.id)}>{props.name}</div>
			);
		}
};

export default DayToggle;