import React from 'react';

const AllToggle = (props) => {
        if(props.selected){
            return(<div onClick={props.handleClick} className="btn btn-sm btn-primary toggle-shortcut">All</div>);
        }else{
            return(<div onClick={props.handleClick} className="btn btn-sm btn-secondary toggle-shortcut">All</div>);
        }
    };

export default AllToggle;