import React from 'react';

const WeekendToggle = (props) => {
    if(props.selected){
        return(<button onClick={props.handleClick} className="btn btn-sm btn-primary toggle-shortcut">Weekends</button>);
    }else{
        return(<button onClick={props.handleClick} className="btn btn-sm btn-secondary toggle-shortcut">Weekends</button>);
    }
};

export default WeekendToggle;