import React from 'react';

const DayBadge = (props) => {

	if (props.selected === true) {
		return (
			<div className="m-1 day-badge">
				{props.name}
			</div>
		);
	}else{
		return(null);
	}

}

export default DayBadge;