import Selector from './components/Selector'

function App() {

  return (
    <div className="App">
      <Selector />
    </div>
  );
}

export default App;
