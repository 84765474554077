import React from 'react';

const WeekdayToggle = (props) => {
    if(props.selected){
        return(<div onClick={props.handleClick} className="btn btn-sm btn-primary toggle-shortcut">Weekdays</div>);
    }else{
        return(<div onClick={props.handleClick} className="btn btn-sm btn-secondary toggle-shortcut">Weekdays</div>);
    }
};

export default WeekdayToggle;