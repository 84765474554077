import React from 'react'
import DayToggle from './DayToggle'
import WeekdayToggle from './WeekdayToggle'
import WeekendToggle from './WeekendToggle'
import AllToggle from './AllToggle'
import Availability from './Availability'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'

class Selector extends React.Component {

		defaultDays = [
			{
					name:"Su",
					id: 0,
					selected: false
			},
			{
					name:"M",
					id: 1,
					selected:false
			},
			{
					name:"T",
					id: 2,
					selected:false
			},
			{
					name:"W",
					id: 3,
					selected:false
			},
			{
					name:"Th",
					id: 4,
					selected:false
			},
			{
					name:"F",
					id: 5,
					selected: false
			},
			{
					name:"Sa",
					id: 6,
					selected: false
			}
		];
		
		toastDuration = 2200;

		constructor(props){
				super(props);
				this.state = {
						days: [],
						start: '',
						end: '',
						weekdaysSelected: false,
						weekendSelected: false,
						allSelected: false,
						availabilities: []
				}
				
		}

		componentDidMount(){
			this.setState({
				days: this.defaultDays
			});
		}

		
		// add current selections as an availability and reset everything to default
		addAvailability(){
			let arr = this.state.availabilities;
			console.log(arr);
			
			if(this.state.start === "" || this.state.end === "") {
				toast.error("Oops! You must enter a start and end time.", {autoClose: this.toastDuration});
				return;
			}

			if(!this.state.days.some((o) => { if(o.selected) { return true; } return false; })) {
				toast.error("Oops! You must select at least one day.", {autoClose: this.toastDuration});
				return;
			}

			let temp_days = JSON.parse(JSON.stringify(this.state.days));

			let avail = {
				id: arr.length,
				days: temp_days,
				start: this.state.start,
				end: this.state.end
			}
			arr.push(avail);
			
			this.setState({
				availabilities: arr,
				days: this.state.days.map((o) => {o.selected = false; return o;}),
				start: '',
				end: '',
				weekdaysSelected: false,
				weekendSelected: false,
				allSelected: false
			});

			toast.success("Availability added!", {autoClose: this.toastDuration});
		}

		// toggle selected values for individually clicked days
		handleDayToggle(keyvalue){
			let arr = this.state.days;
			let obj = arr.find((o, i) => {
				if (o.id === keyvalue) {
					arr[i].selected = !arr[i].selected;
					return true;
				}
				return false;
			});
			this.setState({ days: arr });
			console.log(obj);
		}

		// toggles weekdays on and other days off
		toggleWeekdays(){
			let flip = this.state.weekdaysSelected;
			let arr = this.state.days;
			arr.map((o) => {
				if (o.id < 6 && o.id > 0) {
					o.selected = !flip;
					return true;
					}	
					else {
						o.selected = false;
						return false;
					}
				});
			this.setState({
				weekendSelected: false,
				weekdaysSelected: !flip,
				allSelected: false,
				days: arr
			});
		}

		// toggles weekends on and other days off
		toggleWeekends(){
			let flip = this.state.weekendSelected;
			let arr = this.state.days;
			arr.map((o) => {
				if (o.id === 6 || o.id === 0) {
					o.selected = !flip;
					return true;
					}
					else {
						o.selected = false;
						return false;
					}
				});
			this.setState({
				weekdaysSelected: false,
				allSelected: false,
				weekendSelected: !flip,
				days: arr
			});
		}

		// remove availability with the given id
		removeAvailability(key)
		{
			let arr = this.state.availabilities;
			arr.find((o, i) => {
				if (o.id === key) {
					arr.splice(i,1);
					return true;
				}
				return false;
			});
			this.setState({ availabilities: arr });
			toast.info("Availability removed!", {duration: this.toastDuration});
		}

		// toggles availability for all days
		toggleAll(){
			let flip = this.state.allSelected;
			let arr = this.state.days;
			arr.map((o) => (o.selected = !flip));
			this.setState({
				allSelected: !flip,
				weekendSelected: false,
				weekdaysSelected: false,
				days: arr
			});
		}

		updateStartValue(e){
			this.setState({ start: e.target.value });
			return;
		}

		updateEndValue(e){
			this.setState({ end: e.target.value });
			return;
		}
		
		submitAvailabilityToServer(){
			let arr = this.state.availabilities;
			let data = { availabilities: arr };
			console.log(data);
			axios.post('/api/availability', data).then((res) => {
				console.log(res);
				toast.success("Availability saved!", {autoClose: this.toastDuration});
			}).catch((err) => {
				console.log(err);
				toast.error("Oops! Something went wrong.", {autoClose: this.toastDuration});
			});
		}

		render(){
				return(
						<div className="selector mt-4 p-2">
								<div className="d-flex">
									<h6>Availability Selector</h6>
								</div>
								<div className="d-flex justify-content-center">
									<div className="d-flex w-auto justify-content-evenly m-1 border">
										{this.state.days.map((day, index) => {
											return <DayToggle key={day.id} {...day} handleClick={this.handleDayToggle.bind(this)} />;
										})}
									</div>
								</div>
								<div className="d-flex justify-content-center m-1 toggle-row">
										<WeekdayToggle handleClick={this.toggleWeekdays.bind(this)} selected={this.state.weekdaysSelected} />
										<WeekendToggle handleClick={this.toggleWeekends.bind(this)} selected={this.state.weekendSelected} />
										<AllToggle handleClick={this.toggleAll.bind(this)} selected={this.state.allSelected} />
								</div>
								<div className="d-flex justify-content-evenly m-1 input-row">
									<div className="form-group">
										<label for="start-time">From:</label>
										<input id="start-time" className="form-control" type="time" value={this.state.start} onChange={evt => this.updateStartValue(evt)}></input>
									</div>
									<div className="form-group">
										<label for="end-time">Until:</label>
										<input id="end-time" className="form-control" type="time" value={this.state.end} onChange={evt => this.updateEndValue(evt)}></input>
									</div>
								</div>
								<div className="d-flex justify-content-end submit-row">
									<button className="btn btn-small btn-primary toggle-shortcut" onClick={this.addAvailability.bind(this)}>+Add Availability</button>
								</div>
								<div className="mt-3">
									{this.state.availabilities.map((item, index) => { return <Availability key={item.id} {...item} removeAvailability={this.removeAvailability.bind(this)} />; })}
								</div>
								<ToastContainer />
						</div>
				);
		}
}

export default Selector;