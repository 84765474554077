import React from 'react';
import DayBadge from './DayBadge';
import { Trash } from 'phosphor-react';

const Availability = (props) => {

	const convertToTwelveTime = (time) => {
		let hours = parseInt(time.split(':')[0]);
		let minutes = time.split(':')[1];
		let ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		return hours + ':' + minutes + ampm;
	}
	
	return(
		<div className="d-flex border border-secondary m-2">
			<div className="p-1 d-flex flex-column justify-content-between w-75 justify-self-start">
				<div className="p-1 d-flex">
					{props.days.map((day, i) => { return <DayBadge key={i} {...day} /> })}
				</div>
				<div className="p-1 d-flex h-100 align-items-center avail-time">
						{convertToTwelveTime(props.start)} - {convertToTwelveTime(props.end)}
				</div>
			</div>
			<div onClick={() => props.removeAvailability(props.id)} className="p-1 d-flex h-100 w-25 align-items-center justify-content-end">
				<button className="btn p-1 avail-time"><Trash size={18} /></button>
			</div>
		</div>
	);
};

export default Availability;